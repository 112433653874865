import {
  getGFOMerchantUrl,
  getMerchantUrl,
} from '@artemis/store/merchant/selectors';
import {
  getOrderSource,
  getOrderSourceOrigin,
} from '@artemis/store/order/selectors';
import { ORDER_SOURCE } from '@artemis/utils/constants';
import usePersistedQuery from '@artemis/utils/query/usePersistedQuery';
import { useSelector } from 'react-redux';

const useMerchantMenuUrl = ({ additionalParams } = {}) => {
  const persistedQuery = usePersistedQuery({ additionalParams });
  const merchantPath = useSelector(getMerchantUrl);
  const gfoMerchantPath = useSelector(getGFOMerchantUrl);
  const orderSource = useSelector(getOrderSource);
  const orderSourceOrigin = useSelector(getOrderSourceOrigin);

  if (orderSource === ORDER_SOURCE.GOOGLE_FOOD) {
    return `${orderSourceOrigin}${gfoMerchantPath}${persistedQuery}`;
  }
  return `${orderSourceOrigin}${merchantPath}${persistedQuery}`;
};

export default useMerchantMenuUrl;
