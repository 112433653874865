import React, { useEffect } from 'react';
import { FormattedMessage } from '@artemis/integrations/contentful/utils';
import { setMissedModal } from '@artemis/store/groupOrder/slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFulfillmentState,
  getIsMissedModalOpen,
} from '@artemis/store/groupOrder/selectors';
import { FULFILLMENT_STATE } from '@artemis/utils/constants';
import useMerchantMenuUrl from '@artemis/containers/Menu/useMerchantMenuUrl';
import StandardPrompt from '@artemis/components/StandardPrompt';

const MissedGroupOrderModal = () => {
  const dispatch = useDispatch();
  const merchantMenuURL = useMerchantMenuUrl();
  const isOpen = useSelector(getIsMissedModalOpen);
  const fulfillmentState = useSelector(getFulfillmentState);

  const onClose = () => {
    dispatch(setMissedModal({ isOpen: false }));
    window.location.assign(merchantMenuURL);
  };

  useEffect(() => {
    if (fulfillmentState === FULFILLMENT_STATE.CLOSED) {
      dispatch(setMissedModal({ isOpen: true }));
    }
  }, [fulfillmentState]);

  return (
    <StandardPrompt
      primaryButtonText={<FormattedMessage entry="global.okay" />}
      title={<FormattedMessage entry="groupOrder.missedModal.title" />}
      content={<FormattedMessage entry="groupOrder.missedModal.description" />}
      onClose={onClose}
      isOpen={isOpen}
      onClickPrimaryButton={onClose}
      isClosable={false}
      size="sm"
    />
  );
};

export default MissedGroupOrderModal;
